import { functions } from '@/firebase';

const callableStartProgram = functions.httpsCallable('callableStartProgram');

// useMock = true uses startProgramMockup to mock starting a program instead of starting a program
const useMock = false;

function compareDateStrDesc(a, b) {
  if (a.eventAt < b.eventAt) {
    return -1;
  }
  if (a.eventAt > b.eventAt) {
    return 1;
  }
  return 0;
}

async function startProgramMockup() { 
  await new Promise((resolve) => { 
    setTimeout(resolve, 1000); // 1000 milliseconds = 1 seconds 
  }); 

  const result = { 
    data: { 
      programId: 'uGnMTGgz5IPQslqpFpUK', 
      invitations: [ 
        { role: 'observer', email: '', code: '1DJJ6' }, 
        { role: 'supporter', email: 'sveinn@beanfee.com', code: 'I44J6' }, 
        { role: 'subject', email: '', code: '33JJ6' }, 
      ], 
    },
  }; 

  return result; 
}

function randomLetter() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return characters.charAt(Math.floor(Math.random() * characters.length));
}

const saveStateToLocalStorage = (state) => {
  // Retrieve the array of saved states from localStorage
  const savedStates = JSON.parse(localStorage.getItem('startProgramStates')) || [];

  const stateWithTimestamp = {
    ...state,
    timestamp: new Date().toISOString(),
  };

  // Add the new state to the array
  savedStates.push(stateWithTimestamp);

  // Trim the array to keep only the last 10 states
  if (savedStates.length > 10) {
    savedStates.shift();
  }

  // Save the updated array back to localStorage
  localStorage.setItem('startProgramStates', JSON.stringify(savedStates));
};

const loadStateFromLocalStorage = () => {
  // Retrieve the array of saved states from localStorage
  const savedStates = JSON.parse(localStorage.getItem('startProgramStates'));

  // Return the array of saved states, or an empty array if it doesn't exist
  return savedStates || [];
};


const getDefaultState = () => ({
  templateId: null,
  roles: {
    subject: { nickname: `Nemandi-${randomLetter()}`, me: false, email: '' },
    supporter: { nickname: `Stuðningsaðili-${randomLetter()}`, me: false, email: '' },
    observer: { nickname: `Áhorfandi-${randomLetter()}`, me: false, email: '' },
  },
  schedule: [],
  rewards: [],
  achievements: [],
  settings: {
    report: {
      email: '',
    },
    chat: {
      enabled: true,
      banner: '',
      link: '',
    },
    store: {
      enabled: true,
    },
    feedback: {
      supporterAuto: false,
    },
  },
  result: null,
});

export default {
  state: getDefaultState(),
  mutations: {
    setTemplateId(state, templateId) {
      state.templateId = templateId;
      // saveStateToLocalStorage(state);
    },
    setRoles(state, roles) {
      state.roles = roles;
      // saveStateToLocalStorage(state);
    },
    setSchedule(state, schedule) {
      schedule.sort((a, b) => compareDateStrDesc(a, b));
      state.schedule = schedule;
      // saveStateToLocalStorage(state);
    },
    setRewards(state, rewards) {
      rewards.sort((a, b) => a.successPercentage - b.successPercentage);
      state.rewards = rewards;
      // saveStateToLocalStorage(state);
    },
    setAchievements(state, achievements) {
      achievements.sort((a, b) => a.successPercentage - b.successPercentage);
      state.achievements = achievements;
      // saveStateToLocalStorage(state);
    },
    setSettings(state, settings) {
      state.settings = settings;
      // saveStateToLocalStorage(state);
    },
    setResult(state, result) {
      state.result = result;
      // saveStateToLocalStorage(state);
    },
    loadState(state, newState) {
      Object.assign(state, newState);
    },
    loadAllSavedStates(state, savedStates) {
      state.savedStates = savedStates;
    },
  },
  actions: {
    setTemplateId({ commit }, templateId) {
      commit('setTemplateId', templateId);
    },
    setRoles({ commit }, roles) {
      commit('setRoles', roles);
    },
    setSchedule({ commit }, schedule) {
      commit('setSchedule', schedule);
    },
    setRewards({ commit }, rewards) {
      commit('setRewards', rewards);
    },
    setAchievements({ commit }, achievements) {
      commit('setAchievements', achievements);
    },
    setSettings({ commit }, settings) {
      commit('setSettings', settings);
    },
    setResult({ commit }, result) {
      commit('setResult', result);
    },
    // Load all saved states
    loadState({ commit }) {
      const savedStates = loadStateFromLocalStorage();
      if (savedStates && savedStates.length > 0) {
        commit('loadAllSavedStates', savedStates);
      }
    },
    // Load specific state
    loadSpecificState({ commit }, index) {
      const savedStates = loadStateFromLocalStorage();
      if (savedStates && savedStates.length > 0 && index >= 0 && index < savedStates.length) {
        const specificState = savedStates[index];
        commit('loadState', specificState);
      }
    },
    async startProgram({ commit, getters, state }) {
      commit('setLoading', true);

      let result;

      if (!useMock) {
        result = await callableStartProgram({
          templateId: getters.templateId,
          roles: getters.roles,
          schedule: getters.schedule,
          rewards: getters.rewards,
          achievements: getters.achievements,
          settings: getters.settings,
        });
      } else {
        result = await startProgramMockup();
        console.log(result);
      }
      
      saveStateToLocalStorage(state);

      if (result !== null) {
        commit('setResult', result.data);
        // console.log(result.data); // Debug
      } else {
        commit('setLoading', false);
      }

      commit('setLoading', false);
    },
  },
  getters: {
    templateId(state) {
      return state.templateId;
    },
    roles(state) {
      return state.roles;
    },
    schedule(state) {
      return state.schedule;
    },
    rewards(state) {
      return state.rewards;
    },
    achievements(state) {
      return state.achievements;
    },
    settings(state) {
      return state.settings;
    },
    result(state) {
      return state.result;
    },
  },
};



// https://github.com/c-trimm/moment-recur
