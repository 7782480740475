<template>
  <v-container>
    <v-sheet height="64">
      <v-toolbar
        flat
      >
        <v-btn
          x-small
          @click="clearEvents"
        >
          <v-icon left>
            mdi-trash-can
          </v-icon>
          Clear All
        </v-btn>
        <v-spacer />
        <span
          v-if="$refs.calendar"
          class="mr-5"
        >
          {{ $refs.calendar.title }}
        </span>
        <v-btn
          small
          fab
          text
          @click="prev"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn
          small
          fab
          text
          @click="next"
        >
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </v-toolbar>
    </v-sheet>

    <v-card>
      <v-sheet height="500">
        <v-calendar
          ref="calendar"
          v-model="focus"
          type="month"
          event-text-color="secondary"
          event-color="color"
          style="font-weight: bold"
          :events="coloredEvents"
          @click:event="showEvent"
          @click:date="addEventsDialog"
          @click:more="showMoreDialog"
        />

        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-y
          class="mt-20"
        >
          <v-card
            min-width="350px"
            flat
          >
            <v-toolbar
              color="primary"
            >
              <v-toolbar-title> {{ selectedEvent.name }} </v-toolbar-title>
              <v-spacer />
              <v-btn
                icon
                @click="showDeleteDialog()"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <div>
                Support Feedback
                <span class="title">{{ selectedEvent.supporterFeedbackAfterMinutes }}</span>
                minutes after
              </div>

              <div v-if="selectedEvent.supporterSendReminder">
                Support Reminder
                <span class="title">{{ selectedEvent.supporterReminderBeforeMinutes }}</span>
                minutes before
              </div>
              <v-divider class="my-2" />

              <div v-if="selectedEvent.subjectSendReminder">
                Subject Reminder
                <span class="title">{{ selectedEvent.subjectReminderBeforeMinutes }}</span>
                minutes before
              </div>

              Subject Feedback
              <span class="title">{{ selectedEvent.subjectFeedbackAfterMinutes }}</span>
              minutes after
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                text
                @click="selectedOpen = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-card>
    <AddEventsDialog
      v-model="showAddEventsDialog"
      :date="selectedDate"
      @add-events="addEvents"
    />
    <ShowMoreEventsDialog
      v-model="showMoreEventsDialog"
      :date="selectedDate"
      @delete-event="deleteEvent"
    />

    <v-dialog 
      v-model="deleteDialog" 
      max-width="400"
    >
      <v-card>
        <v-card-title 
          class="headline"
        >
          Delete Event(s)
        </v-card-title>
        <v-card-text>
          <div 
            v-if="isRecurring"
          >
            <p>Do you want to delete this event only or all occurrences?</p>
          </div>
          <v-btn 
            block
            @click="removeSelectedEvent()"
          >
            This event only
          </v-btn>
          <v-btn 
            v-if="isRecurring"
            block
            class="mt-4"
            @click="deleteEventSeries()"
          >
            All events in series
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn 
            color="primary" 
            text 
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>

import moment from 'moment';

import AddEventsDialog from '@/components/programs/start/AddEventsDialog.vue';
import ShowMoreEventsDialog from '@/components/programs/start/ShowMoreEventsDialog.vue';

export default {
  name: 'ProgramDefineSchedule',
  components: {
    AddEventsDialog,
    ShowMoreEventsDialog,
  },
  data() {
    return {
      focus: moment(new Date()).startOf('day').format('YYYY-MM-DD'),

      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      start: null,
      end: null,

      selectedDate: '',
      showAddEventsDialog: false,
      showMoreEventsDialog: false,

      deleteDialog: false,
      isRecurring: false,
    };
  },
  computed: {
    schedule() {
      return this.$store.getters.schedule;
    },
    user() {
      return this.$store.getters.user;
    },
    coloredEvents() {
      return this.schedule.map((event) => ({
        ...event,
        color: event.eventColor,
      }));
    },
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },

  methods: {
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    clearEvents() {
      this.$store.dispatch('setSchedule', []);
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => { this.selectedOpen = true; }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    showMoreDialog({ date }) {
      this.selectedDate = date;
      console.log(date);
      this.showMoreEventsDialog = true;
    },

    showDeleteDialog() {
      this.isRecurring = this.selectedEvent.eventSeriesUID;
      this.deleteDialog = true;
    },

    deleteEvent(index) {
      this.schedule.splice(index, 1);
    },

    deleteEventSeries() {
      const updatedSchedule = this.schedule.filter(
        (event) => event.eventSeriesUID !== this.selectedEvent.eventSeriesUID,
      );

      this.$store.dispatch('setSchedule', updatedSchedule);

      this.deleteDialog = false;
    },

    removeSelectedEvent() {
      this.selectedOpen = false;

      const idx = this.schedule.findIndex((event) => event.eventAt === this.selectedEvent.eventAt);
      this.schedule.splice(idx, 1);

      this.deleteDialog = false;
    },

    addEventsDialog({ date }) {
      this.selectedDate = date;
      this.showAddEventsDialog = true;
    },

    addEvents(eventsData) {
      const newSchedule = [...this.schedule, ...eventsData];
      this.$store.dispatch('setSchedule', newSchedule);
    },

  },

};
</script>
