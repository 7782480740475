<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
  >
    <v-card>
      <v-card-title class="headline">
        Events for {{ date | formatDate }}
      </v-card-title>

      <v-card-text>
        <div
          v-for="(event, index) in coloredEvents"
          :key="event"
        >
          <v-card
            v-if="event.start === date"
            class="mb-2 pa-4"
            :style="{ backgroundColor: event.eventColor }"
          >
            <v-row>
              <v-col md="8">
                <h3>
                  {{ event.time }}
                </h3>

                <v-divider class="my-2" />

                <div>
                  Support Feedback
                  <span class="title">{{ event.supporterFeedbackAfterMinutes }}</span>
                  minutes after
                </div>

                <div v-if="event.supporterSendReminder">
                  Support Reminder
                  <span class="title">{{ event.supporterReminderBeforeMinutes }}</span>
                  minutes before
                </div>
                <v-divider class="my-2" />

                <div v-if="event.subjectSendReminder">
                  Subject Reminder
                  <span class="title">{{ event.subjectReminderBeforeMinutes }}</span>
                  minutes before
                </div>

                Subject Feedback
                <span class="title">{{ event.subjectFeedbackAfterMinutes }}</span>
                minutes after
              </v-col>
              <v-col md="4">
                <v-btn
                  color="red lighten-3"
                  block
                  small
                  @click="deleteEvent(index)"
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-delete
                  </v-icon>Delete
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>


export default {

  name: 'ShowMoreEventsDialog',

  props: {
    value: Boolean,
    date: { type: String, required: true },
  },

  data() {
    return {

    };
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    schedule() {
      return this.$store.getters.schedule;
    },

    coloredEvents() {
      return this.schedule.map((event) => ({
        ...event,
        eventColor: event.eventColor === 'primary' ? '#FFA45B' : event.eventColor,
      }));
    },

  },

  methods: {
    deleteEvent(index) {
      this.$emit('delete-event', index);
    },
  },

};
</script>
